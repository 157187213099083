import React from "react";
import { Link } from "react-router-dom";
import Backgroud from './enrutamiento.jpg';

const CardEnrutamiento = () => {

    return (
    <div>
<div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="cardSinborde card-responsive mb-3" >
  <div class="row g-1">
    <div class="col-md-6">
    <br/><br/>
    <img src='./img/home/enruta.png' height='auto' width='100%'  alt=""/>     
    </div>
    <div class="col-md-6">
    <br/><br/><br/>
    <ul className='col-1 col-md-11 list-unstyled'>
                     <h6 className='card-title'>ENRUTAMIENTO</h6>   
                      <h3 className='card-text subtitulo-enrutamiento'>Asigne Rutas De Cobro y Venta Eficientes a Sus Colaboradores</h3>
                      <p className='card-text parrafo-enrutamiento'> Prescinda de la necesidad de planificación de rutas por parte de sus colaboradores y asigne las tareas de ruta para cada uno. Gane confianza en que los integrantes de su equipo están realizando sus actividades en tiempo y forma.</p>
                      <li className='card-text lista-enrutamiento text-left'> <img src='./img/home/lista.png' height='auto' width='8%' alt=""/>Planifique las rutas para cada colaborador</li>
                      <li className='lista-enrutamiento text-left'> <img src='./img/home/lista.png' height='auto' width='8%' alt=""/>Reduzca el tiempo en la actividad de cada vendedor o repartidor</li>
                      <li className='lista-enrutamiento text-left'> <img src='./img/home/lista.png' height='auto' width='8%' alt=""/>Priorice llegadas para ventas o entregas</li>
                      <li className='lista-enrutamiento text-left'> <img src='./img/home/lista.png' height='auto' width='8%' alt=""/>Monitorice y genere reportes de los movimientos del día</li>
                      <Link to='/pruebagratis' ><img src='./img/home/comenzar.png' height='auto' width='60%' alt=""/></Link>
    </ul>
    </div>
  </div>
  </div>
</div>
<div class="col-md-1"></div>
</div>

</div>
    <br/>
    <br/>
        </div>
    )
}

export default CardEnrutamiento