import React from "react";
import NavbarPlanes from '../Navbar/NavbarPlanes';
import CardPlanesPregunta from "../card/CardPlanesPregunta";
import CardTipoPlan from "../card/CardTipoPlan";
import CardBeneficios from "../card/CardBeneficios";
import CardIngreso from "../card/CardIngreso";
import CardSuscripcion from "../card/CardSuscripcion";
import CardInterfaz from "../card/CardInterfaz";
import FooterFuncion from "../footer/FooterFuncion";

const Planesprecios = ({cartItems, productItems , handleAddProduct}) => {

    return (
        <div>
        <NavbarPlanes cartItems={cartItems}/>
        <CardPlanesPregunta/>
        <CardTipoPlan productItems={productItems} handleAddProduct={handleAddProduct}/>
        <CardBeneficios/>
        <CardIngreso/>
        <CardSuscripcion/>
        <CardInterfaz/>
        <FooterFuncion/>
        </div>
    )
}

export default Planesprecios