import React from "react";
import './Card.css'
import { Link } from "react-router-dom";

const CardConfidencialidad = () => {
return (
  <div>
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<br/>
  <div class="container-fluid">
  <div class="cardSinborde card mb-3">
  <div class="row g-5">
    <div class="col-md-6">
      <div class="card-body">
        <h6 class="card-title">Confidencialidad y Transparencia</h6>
        <h2 class="card-title">Seguridad En Todos Tus Movimientos</h2>
        <p class="parrafoFuncion">El ecosistema VxRuta guarda toda información relacionada con las ventas, genere informes de fechas anteriores. Encuentre detalles de la venta, notas agregadas, fechas de visitas pagos o adeudos y enterese de todos los movimientos de venta de su negocio.</p>
        <p class="parrafoFuncion"><strong>Datos de Clientes</strong><br/>VxRuta almacena datos básicos de contacto y control de venta de cada cliente para ofrecer una atención personalizada, el sistema no compromete ni comparte por algún motivo información que exponga la relación vendedor cliente.</p>
        <p class="parrafoFuncion"><strong>Datos de Colaboradores</strong><br/>VxRuta gestiona los datos de salida (rutas de venta) y entrada, información de pedidos, cancelaciones, visitas, etc. Los colaboradores se encuentran sujetos a compartir información únicamente con el sistema VxRuta, y solamente aquella relacionada con la venta.</p>
        <p class="nota"> <Link to='/pruebagratis'><img src='./img/funcion/comenzar.png' height='auto' width='40%' alt=""/></Link>¡No se requiere tarjeta de crédito!</p>
      </div>
    </div>
    <div class="col-md-6">
    <img src='./img/funcion/seguridad.png'height='auto' width='100%' alt=""/>
    </div>
  </div>
</div>
</div>
</div>
<div class="col-md-1"></div>
</div>

</div>
    )
}

export default CardConfidencialidad