import React from "react";
import { Link } from "react-router-dom";
import Backgroud from './dudas.png';

const CardDudas = () => {

return (
<div>
<div class="row"> 
 <div class="col-md-1"></div>
 <div class="col-md-10" >
 <h6 class="titulo-pantalla">CONTÁCTANOS</h6>
 <br/>
 <div class="row"> 
 <div class="col-md-2">
 </div>
 <div class="col-md-8">
 <h3 class="subtitulo-prueba">Escriba a Nuestro Correo o llame Directamente a Nuestros Números De Contacto</h3>
 <p class="boton-formulario" ><Link to='/contactanos'><img src='./img/prueba/formulario.png' height='auto' width='35%' alt=""/></Link></p>

 </div>
 <div class="col-md-2">
 </div>
 </div> 
 <br/>

 <div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
 <br/><br/>
 <p class="card-title-pedido"alingn="center">DUDAS Y QUEJAS</p>
<h3 class="card-title-pregunta" alingn="center">¿Te queda alguna duda acerca de los planes y propósitos de "VxRuta" y deseas recibir más información?</h3><br/><br/>
<div class="row">
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="nombre" name="nombre" placeholder="Nombre" required readonly/>
</div>
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="telefono" name="telefono" placeholder="Teléfono" required readonly/>
</div>
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="correo" name="correo" placeholder="Correo" required readonly/>
</div>  
</div>
<br/>
<div class="row">
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="asunto" name="asunto" placeholder="Asunto" required readonly/>
</div>
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="vendedores" name="vendedores" placeholder="Número de vendedores" required readonly/>
</div>
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="plan" name="plan" placeholder="Me interesó el plan" required readonly/>
</div>  
</div>
<br/>
<div class="row">
<div class="form-group col-md-6">
<textarea type="text" name="textarea" rows="2" cols="58" placeholder="Mensaje"></textarea>
</div>
<div class="form-group col-md-2">
</div> 
<div class="form-group col-md-4">
<button type="submit" class ="registrar col-md-12" name="action_button" id="action_button" value="Add"><img src='./img/prueba/ENVIAR.png' height='auto' width='100%' alt=""/></button>
</div>  
</div>
<br/><br/><br/>
 
 </div>   
</div>
<div class="col-md-1"></div>
</div>

    <br/>
    <br/>
    </div>
    )
}

export default CardDudas