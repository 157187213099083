const data ={
    productItems:[
        {
            id: "1",
            name:"Pyme",
            subtitulo:"Plan 1 a 10 conexiones",
            price:"549"

        },
        {
            id: "2",
            name:"Startup",
            subtitulo:"Plan 10 a 25 conexiones",
            price:"449"

        },
        {
            id: "3",
            name:"Business",
            subtitulo:"Plan 25 a 50 conexiones",
            price:"399"

        },
    ]

}

export default data;