import React from "react";
import Backgroud from './dudas.png';

const CardDudas = () => {

return (
<div>
<div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<br/><br/>
<p class="card-title-pedido"alingn="center">DUDAS Y QUEJAS</p>
<h3 class="card-title-pregunta" alingn="center">¿Te queda alguna duda acerca de los planes y propósitos de "VxRuta" y deseas recibir más información?</h3><br/><br/>
<div class="row">
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="nombre" name="nombre" placeholder="Nombre" required readonly/>
</div>
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="telefono" name="telefono" placeholder="Teléfono" required readonly/>
</div>
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="correo" name="correo" placeholder="Correo" required readonly/>
</div>  
</div>
<br/>
<div class="row">
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="asunto" name="asunto" placeholder="Asunto" required readonly/>
</div>
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="vendedores" name="vendedores" placeholder="Número de vendedores" required readonly/>
</div>
<div class="form-group col-md-4">
     <input type="text"  class="form-control" id="plan" name="plan" placeholder="Me interesó el plan" required readonly/>
</div>  
</div>
<br/>
<div class="row">
<div class="form-group col-md-6">
<textarea type="text" name="textarea" rows="3" cols="60" placeholder="Mensaje"></textarea>
</div>
<div class="form-group col-md-2">
</div> 
<div class="form-group col-md-4">
<button type="submit" class ="registrar col-md-12" name="action_button" id="action_button" value="Add"><img src='./img/home/ENVIAR.png' height='auto' width='100%' alt=""/></button>
</div>  
</div>
<br/><br/><br/><br/>
</div>
<div class="col-md-1"></div>
</div>
</div>
<br/><br/>
  
    </div>
    )
}

export default CardDudas