import React from "react";
import Backgroud from './cardingreso.png';

const CardIngreso = () => {

return (
<div>
<div class="row"> 
 <div class="col-md-1">
 </div>
 <div class="col-md-10" >
 <div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
    <br/>
    <br/>   
    <br/> 
    <h3 class="card-title-pregunta" alingn="center">Obtenga el Ingreso Hoy Mismo. Es Gratis.</h3>
    <br/><br/>
</div>
</div>
<div class="col-md-1">
</div>
</div>
<br/>
</div>
    )
}

export default CardIngreso