import React from "react";
import CardFuncion from "../card/CardFuncion";
import CardModulos from "../card/CardModulos";
import CardCaracteristicas from "../card/CardCaracteristicas";
import CardConfidencialidad from "../card/CardConfidencialidad";
import CardSoftware from "../card/CardSoftware";
import FooterFuncion from "../footer/FooterFuncion";
import NavbarFuncion from "../Navbar/NavbarFuncion";

const Comofunciona = () => {
    return (
        <div>
        <NavbarFuncion/>
        <CardFuncion/>
        <CardModulos/>
        <CardCaracteristicas/>
        <CardConfidencialidad/>
        <CardSoftware/>
        <FooterFuncion/>
        </div>
    )
}

export default Comofunciona