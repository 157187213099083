import React from "react";
import './Card.css'
import { Link } from "react-router-dom";

const CardFuncion = () => {
return (
  <div>
  <br/><br/>
  <div class="container-fluid">
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="cardSinborde card mb-3">
  <div class="row g-5">
    <div class="col-md-6">
      <div class="card-body">
        <h6 class="card-title">Funcionamiento de VxRuta</h6>
        <h2 class="card-title">Simplifique Sus Operaciones De Venta En Ruta Y Automatice La gestión Fácilmente</h2>
        <p class="parrafoFuncion">Planifique, diseñe y optimice las rutas de venta y entrega de manera eficiente, asigne tareas específicas a cada uno de sus agentes de ventas y cumpla con los propósitos pensados, lleve la monitorización de todos los procesos de venta y tome el control de su negocio.</p>
        <p class="parrafoFuncion"><strong>Aumente La Eficacia</strong><br/> El sistema VxRuta planifica el día de sus agentes de ventas, lo que ayuda a aumentar su eficacia en campo.</p>
        <ul className=' list-unstyled'>
                      <li className='textplan text-left'> <img src='./img/funcion/lista.png' height='auto' width='8%'  alt=""/>La planificación de rutas permiten más citas en un día</li>
                      <li className='textplan text-left'> <img src='./img/funcion/lista.png' height='auto' width='8%'  alt=""/>El mapeo he historial de clientes ayuda a mejorar la atención y cerrar oportunidades de venta</li>
                      <li className='textplan text-left'> <img src='./img/funcion/lista.png' height='auto' width='8%' alt=""/>El sistema permite levantar pedidos, cotizaciones o pagos de manera fácil</li>
                     </ul>
        <p class="nota"> <Link to='/pruebagratis'><img src='./img/funcion/comenzar.png'  height='auto' width='50%' alt=""/></Link>¡No se requiere tarjeta de crédito!</p>
      </div>
    </div>
    <div class="col-md-6">
    <img src='./img/funcion/funcion.png' height='auto' width='100%' alt=""/>
    </div>
  </div>
</div>
</div>
<div class="col-md-1"></div>
</div> 
</div>
</div>
    )
}

export default CardFuncion