import React from "react";
import { Link } from "react-router-dom";
import './Footer.css'

const Footer = () => {

    return (
        <div>
         <footer className='text-white py-4 bg-light'>
         <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
        <nav className='row'>
                    
                    <ul className='col-12 col-md-3 list-unstyled'>
                    <li className='text-left'> 
                    <Link to='/' className='d-flex aling-items-center justyfy-content-center'>
                    <img src='../img/home/logotipo.png' className='mx-2' height='auto' width='100%' alt=""/>
                    </Link>
                    <p class="parrafo1 justyfy-content-center">El sistema VxRuta te permite el control de tu negocio, Alista seguimiento de tus ventas.</p>
                    </li>    
                    </ul>
                    
                    <ul className='col-12 col-md-2 list-unstyled'>
                    <p className='titulof font-weight-bold mb-2'>Ubicación</p>   
                     <p class="parrafo1">Miguel Cabrera #627 Centro Oaxaca, México</p>
                    </ul>

                    <ul className='col-12 col-md-2 list-unstyled'>
                    <p className='titulof font-weight-bold mb-2'>Teléfonos</p>
                    <a href="tel:5564560704" class="parrafo1"><i class="whatsapp bi bi-whatsapp"/>5564560704 </a>
                    <a href="tel:9511671455" class="parrafo1"><i class="fa fa-phone"/>9511671455 </a>
                    
                    </ul>
                    <ul className='col-12 col-md-2 list-unstyled'>
                    <p className='titulof font-weight-bold mb-2'>Redes sociales</p>   
                    <li class='d-flex justify-content-between'>
                    <a href="https://facebook.com/attimx/"> <i class="facebook bi bi-facebook"/>  </a>
                    <a href="https://facebook.com/attimx/"><i class="messenger bi bi-messenger"/> </a>
                    <a href="https://instagram.com/attiimx/"><i class="instagram bi bi-instagram"/> </a>
                    </li>
                    </ul>
                    <ul className='col-12 col-md-3 list-unstyled'>
                    <p className='titulof font-weight-bold mb-2'>Más de nosotros</p>   
                    <li ><a href='https://atti.com.mx' className="atti"> www.atti.com.mx </a></li> 
                    </ul>
                   <div className='container'>
                       <p className='pie text-center'>Copyright@2021 ATTI SOLUTIONS All Rights Reserved</p>
                   </div>
                </nav>
        </div>
        <div class="col-md-1"></div>
        </div>
                 
         </footer>
        </div>
    )
}

export default Footer