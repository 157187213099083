import { Link } from "react-router-dom"
import React from "react";
import Backgroud from './cardEficacia.png';

const cardEficacia = () => {

    return (
    <div>
    <div class="row"> 
    <div class="col-md-1"></div>
    <div class="col-md-10">
    <div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
    <div class="container-fluid" >
   <div class="row"> 
   <div class="col-md-6"> 
   <br/><br/><br/><br/><br/>
   <p class="d-titulo-eficacia">Aumente la eficacia de su negocio</p>
   <h2 class="d-subtitulo">Optimizar al Máximo Su Trabajo de Venta en Ruta, ahorre tiempo, dinero y gane precisión.</h2>
   <p class="d-desgarga" >Administre a su personal de campo, brindandoles las herramientas necesarias para ejercer su trabajo de manera correcta. Permita el acceso a inventarios, ubicaciones, precios, disponibilidad de stock. Gane precisión en los inovimientos de sus colaboradores, ahorre en nómina y mejore la rentabilidad de su negocio.</p>
   <p class="buscarplan"><Link to='/pruebagratis'><img src='./img/prueba/reclamarVersion.png' height='auto' width='50%' alt=""/><label class="buscarplan1">¡No se requiere tarjeta de crédito!</label></Link></p>
   </div>
  <div class="col-md-6">
  <br/><br/>
  <img src='./img/prueba/eficacia.png' height='auto' width='100%' alt=""/>
  </div>
  </div>
  </div>
  <br/>
  </div>
  </div>
  <div class="col-md-1"></div>
  </div>
  <br/><br/><br/>
  </div>
        )
    }
    
    export default cardEficacia