import React from "react";

const CardEvaluacion = () => {

    return (
        <div>
  <div class="container-fluid" >
  <div class="row">
<div class="col-md-1"></div>
<div class="col-md-11">
<h6 class="titulo-pantalla">VERSIÓN DE EVALUACIÓN</h6>
  <h3 class="subtitulo-pantalla">Empieza a Gestionar los recursos de tu negocio!</h3>
 <div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col">
    <div class="cardSinborde h-90">
      <div class="card-body">
      <img src='./img/home/evaluacion.png' height='auto' width='100%' alt=""/>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="cardSinborde h-100">
      <div class="card-body" >
      <form method="post" id="sample_form" class="form-horizontal" enctype="multipart/form-data" autocomplete="off">
                            
                                <div class="form-group">
                                    <label  class="control-label col-md-2"  >Nombre </label>
                                    <div class="col-md-12">
                                    <input type="text" name="nombreCliente" id="nombreCliente" placeholder="Nombre" class="form-control" required />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-2" >Apellido </label>
                                    <div class="col-md-12">
                                    <input type="text" name="apellidoCliente" id="apellidoCliente" placeholder="Apellido" class="form-control" required />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-12" >Número de teléfono</label>
                                    <div class="col-md-12">
                                        <input type="tel" name="telefonoCliente" id="telefonoCliente" placeholder="Número de teléfono"  class="form-control" title="Ingrese un numero telefonico valido" required />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-12" >Dirección de correo electrónico </label>
                                    <div class="col-md-12">
                                        <input type="email" name="correoCliente" id="correoCliente" placeholder="Dirección de correo electrónico" class="form-control" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-3" >Empresa </label>
                                    <div class="col-md-12">
                                        <input type="text" name="empresaCliente" id="empresaCliente" placeholder="Empresa" class="form-control" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-2" >Estado </label>
                                    <div class="col-md-12">
                                    <select type="text" id="estadoCliente" name="estadoCliente" placeholder="Estado" class="form-control" required>
                                            <option value="">Seleccionar</option>
                                                <option value=""></option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-12" >Cantidad de rutas</label>
                                    <div class="col-md-12">
                                        <input type="text" name="rutasCliente" id="rutasCliente" placeholder="Cantidad de rutas" class="form-control" required />
                                    </div>
                                </div>
                                <div class="form-group" >
                                    <input type="hidden" name="action" id="action" />
                                    <input type="hidden" name="hidden_id" id="hidden_id" />
                                    <button type="submit" class ="registrar col-md-12" name="action_button" id="action_button" value="Add"><img src='./img/home/REGISTRARSE.png' height='auto' width='100%' alt=""/></button>
                                </div>
                            </form>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="cardSinborde h-100">
      <div class="card-body">
      <img src='./img/home/version.png' height='auto' width='100%' alt=""/>
      </div>
    </div>
  </div>
</div>
</div>
</div>   
</div>
<br/>
        </div>
    )
}

export default CardEvaluacion