import React from "react";
import { Link } from "react-router-dom";
import '../card/Card.css'
const Columns = () => {

    return (
        <div>
 <div class="container-fluid">
 <div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="row row-cols-7 ">
    <div class="col">
      <div class="p-1 "><Link to='/'><img src='./img/home/administracion.png' height='auto' width='70%' alt=""/></Link></div>
      <p class="colum-menu7">Administración</p>
    </div>
    <div class="col">
      <div class="p-1"><Link to='/'><img src='./img/home/cotizacion.png' height='auto' width='70%' alt=""/></Link></div>
      <p class="colum-menu7">Cotizaciones</p>
    </div>
    <div class="col">
      <div class="p-1"><Link to='/'><img src='./img/home/ventas.png' height='auto' width='70%' alt=""/></Link></div>
      <p class="colum-menu7">Ventas</p>
    </div>
    <div class="col">
      <div class="p-1"><Link to='/'><img src='./img/home/suministrar.png' height='auto' width='70%' alt=""/></Link></div>
      <p class="colum-menu7">Suministrar</p>
    </div>
    <div class="col">
      <div class="p-1"><Link to='/'><img src='./img/home/ubicaciones.png' height='auto' width='70%' alt=""/></Link></div>
      <p class="colum-menu7">Ubicaciones</p>
    </div>
    <div class="col">
      <div class="p-1"><Link to='/'><img src='./img/home/entregas.png' height='auto' width='70%' alt=""/></Link></div>
      <p class="colum-menu7">Entregas</p>
    </div>
    <div class="col">
      <div class="p-1"><Link to='/'><img src='./img/home/cobranza.png' height='auto' width='70%' alt=""/></Link></div>
      <p class="colum-menu7">Cobranza</p>
    </div>
  </div>
</div>
<div class="col-md-1"></div>
</div>
 
</div>
<br/>
        </div>
    )
}

export default Columns