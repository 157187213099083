import React from "react";
import Backgroud from './cardbeneficios.png';

const CardBeneficios = () => {

return (
<div>
<div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<h6 class="tituloTipoPlan">Beneficios</h6>
<div class="row g-0">
  <div class="col-md-3">
  </div>
    <div class="col-md-7">
      <div class="card-body">
      <h2 class="titulo-interfaz">Como El Sistema De VxRuta Aumenta La Productividad De Su Equipo</h2>
      </div>
    </div>
    <div class="col-md-2">
    </div>
  </div>

  <div class="container-fluid">
  <div class="row row-cols-3 ">

  <div class="col">
  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/planes/monitoreo.png' height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="textplanp"><strong>Monitoreo En Tiempo Real</strong><br/>Todas las actividades de sus agentes de venta se actualizan en el sistema Vx, realice un seguimiento y de lectura a los movimientos del día.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
    </div>

    <div class="col">
       <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/planes/historiales.png' height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="textplanp"><strong>Historiales</strong><br/>El sistema Vx almacena datos relacionados con ventas a clientes, pedidos, cotizaciones, compras anteriores, comentarios agregados etc.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
    </div>

 <div class="col">
  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/planes/gestion.png' height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="textplanp"><strong>Gestión de Tareas</strong><br/>VxRuta administra las rutas de los colaboradores de ventas. Asigne posiciones en el mapa y tareas para cada uno.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
    </div>

  </div>
</div>

<div class="container-fluid">
  <div class="row row-cols-3 ">

    <div class="col">
    <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/planes/permisos.png' height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="textplanp"><strong>Permisos De Venta</strong><br/>Vx almacena inventarios de Stock, precios, productos en descuento, créditos de cliente, etc. Permita que sus agentes levanten ventas desde la aplicación móvil.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
    </div>

    <div class="col">
       <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/planes/mapavx.png' height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="textplanp"><strong>Mapa De Clientes</strong><br/>VxRuta Almacena información de la cartera de clientes, ubica posiciones de ventas y datos de contacto.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
    </div>

 <div class="col">
  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/planes/fidelizacioncliente.png' height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="textplanp"><strong>Fidelización</strong><br/>El sistema Vx te permite generar parámetros promocionales a productos, seleccione stock, clientes de su inventario y aplique promociones.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
    </div>

  </div>
</div>
</div>
<div class="col-md-1"></div>
</div>

</div>

    <br/><br/><br/>
    </div>
    )
}

export default CardBeneficios