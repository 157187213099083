import React from "react";
import { Link } from "react-router-dom";

const CardServicio = () => {

    return (
        <div>

<div class="container-fluid" >
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="cardSinborde mb-3" >
  <div class="row g-5">
    <div class="col-md-6">
      <div class="card-body">
        <h6>NUESTRO SERVICIO</h6>
        <h2 class="card-title-servicio">Como contratar o probar el sistema de VxRuta</h2>
        <p class="card-text-servicio">El sistema VxRuta le permite tomar el control de venta en ruta de su negocio. Vx le ayuda en tareas diarias como: alistar el levantamiento de cotizaciones, pedidos, reportes, asignaciones y entregas entre otras opciones. VxRuta se adapta a las necesidades de su negocio y agiliza los procesos en cartera de clientes y seguimiento de tus ventas. Evite el extravío o la recaptura tediosa de la información de sus clientes.</p>
        <p class="card-link"><Link to='/pruebagratis'><img src='./img/home/CONTRATAR.png' height='auto' width='50%' alt=""/><label class="buscas"><u>Probar demo 15 días</u></label></Link></p>
      </div>
    </div>
   <div class="col-md-6">
   <img src='./img/home/servicio.png' height='auto' width='100%' alt=""/>
   </div>
   </div>
   </div>
</div>
<div class="col-md-1"></div>
</div>
   </div>

   <br/>
        </div>
    )
}

export default CardServicio