import React from "react";
import { Link } from "react-router-dom";
import './Footer.css'

const FooterFuncion = () => {

    return (
        <div>
         <footer className='footer-funcion py-4'>
         <div class="row">
         <div class="col-md-1"></div>
         <div class="col-md-10">
         <div className='container-fluid'>
                 <nav className='row'>
                 <nav className='row'>
                     <ul className='col-12 col-md-5 list-unstyled'>
                     </ul>                  
                     <ul className='col-12 col-md-4 list-unstyled'>
                     <Link to='/' className='d-flex aling-items-center justyfy-content-center'>
                     <img src='../img/funcion/vxfooter.png' className='mx-4' height='auto' width='100%' alt=""/>
                     </Link>
                     <br/>
                     <ul className='col-12 col-md-12 list-unstyled'>
                     <li class='d-flex justify-content-between'>
                     <a href="https://facebook.com/attimx/"> <i class="f-facebook bi bi-facebook"/>  </a>
                     <a href="https://facebook.com/attimx/"> <i class="f-messenger2 bi bi-messenger"/> </a>
                     <a href="https://instagram.com/attiimx/"> <i class="f-instagram3 bi bi-instagram"/> </a>
                     </li>
                     </ul>
                     </ul>
                     <ul className='col-12 col-md-3 list-unstyled'>
                     </ul>
                 </nav>
                  <hr class="f-hr"/>
                     <ul className='col-12 col-md-3 list-unstyled'>
                     <li className='text-left'> 
                     <p class="text-funcion justyfy-content-center"><i class="f-ubicacion bi bi-geo-alt"></i> Miguel Cabrera #627 Centro Oaxaca, México.</p>
                     </li>    
                     </ul>                  
                     <ul className='col-12 col-md-3 list-unstyled'>
                      <a href="tel:9511671455" class="text-funcion"><i class="f-telefono bi bi-telephone"></i> (951) 167 14 55</a>
                     </ul>
                     <ul className='col-12 col-md-3 list-unstyled'>
                     <a href="tel:5564560704" class="text-funcion"><i class="f-telefono1 bi bi-telephone"></i> (556) 456 07 04</a>
                     </ul>
                     <ul className='col-12 col-md-3 list-unstyled'>
                     <li class="text-funcion"><i class="f-url bi bi-globe"></i> <a href='https://atti.com.mx' className="text-funcion"> www.atti.com.mx </a></li> 
                     </ul>
                    <div className='container'>
                        <p className='piefuncion text-center'>Copyright@2021 ATTI SOLUTIONS All Rights Reserved</p>
                    </div>
                 </nav>
             </div>
         </div>
         <div class="col-md-1"></div>
         </div>
         </footer>
        </div>
    )
}

export default FooterFuncion