import React from "react";
import NavbarPrueba from '../Navbar/NavbarPrueba';
import CardEficacia from "../card/CardEficacia";
import CardPlanesPrueba from "../card/CardPlanesPrueba";
import CardSuscripcionPrueba from "../card/CardSuscripcionPrueba";
import CardDudasPrueba from "../card/CardDudasPrueba";
import FooterFuncion from "../footer/FooterFuncion";


const Pruebagratis = ({cartItems, productItems , handleAddProduct}) => {

    return (
        <div>
        <NavbarPrueba cartItems={cartItems}/>
        <CardEficacia/>
        <CardPlanesPrueba productItems={productItems} handleAddProduct={handleAddProduct}/>
        <CardSuscripcionPrueba/>
        <CardDudasPrueba/>
        <FooterFuncion/>
        </div>
    )
}

export default Pruebagratis