import React from "react";
import { Link } from "react-router-dom";

const CardInterfaz = () => {

    return (
        <div>
 <div class="row"> 
 <div class="col-md-1"></div>
 <div class="col-md-10">
 <div class="container-fluid" >
 <p class="tituloTipoPlan">APP VxRuta</p>  

 <div class="row g-0">
  <div class="col-md-2"></div>
    <div class="col-md-8">
      <div class="card-body">
      <h2 class="titulo-interfaz">Interfaz Sencilla Y Amigable Con el Usuario</h2>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>    

 <div class="container-fluid">
  <div class="row row-cols-5 ">
    <div class="col">
      <div class="p-1 "><Link to='/'><img src='./img/home/interfaz1.png' height='auto' width='100%' alt=""/></Link></div>
    </div>
    <div class="col">
      <div class="p-1"><Link to='/'><img src='./img/home/interfaz2.png' height='auto' width='100%' alt=""/></Link></div>
    </div>
    <div class="col">
      <div class="p-1"><Link to='/'><img src='./img/home/interfaz3.png' height='auto' width='100%' alt=""/></Link></div>
    </div>
    <div class="col">
      <div class="p-1"><Link to='/'><img src='./img/home/interfaz4.png' height='auto' width='100%' alt=""/></Link></div>
    </div>
    <div class="col">
      <div class="p-1"><Link to='/'><img src='./img/home/interfaz5.png' height='auto' width='100%' alt=""/></Link></div>
    </div>
  </div>
</div>
</div>
</div>
<div class="col-md-1"></div>
</div>
<br/>
</div>
    )
}

export default CardInterfaz