import React from "react";
import { Link } from "react-router-dom";
import './Navbar.css';
import Backgroud from './headerPlanes.png';

const NavbarPlanes = ({ cartItems }) => {

return (
<div>
<header className="n-plan" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'cover', position:'relative' }}>
<nav className="navbar navbar-expand-lg navbar-dark">
  <div className="container-fluid">
      <Link to='/'>
      <img src='./img/planes/logoPlanes.png' class="logo-emonedero" alt=""/>
       </Link>
     
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav mx-auto">
        <li className="nav-item">
          <Link className="nav-link l-funcion" aria-current="page" to='/'>Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link l-funcion" to='/comofunciona'>¿como funciona?</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link active" to='/planesprecios'>Planes y precios</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link l-funcion" to='/pruebagratis'>¡Prueba gratis!</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link l-funcion" to='/contactanos'>Contactanos!</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link l-funcion" to='/cart'><i class="cart bi bi-cart3" /><span className="plan-cart-length"> {cartItems.length === 0 ? "": cartItems.length}</span></Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link" to='/pruebagratis'><img src='./img/planes/pruebagratis.png' class="boton-probar" height='auto' width='100%' alt=""/></Link>
        </li>
      </ul>
    </div>
  </div> 
</nav>
<br/><br/><br/><br/>
<h1 className="titulo-funcion">Planes y Precios</h1>
<div className="vertical-line"/>
</header> 
</div>
    )
}

export default NavbarPlanes