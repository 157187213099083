import React from "react";


const CardSugerencias = () => {

    return (
        <div>
            <br/><br/>

<div class="row"> 
<div class="col-md-1"></div>
<div class="col-md-10" >
<div class="container-fluid" >
<div class="cardSinborde mb-3" >
 
      <div class="card-body">
        <h2 class="subtitulo-prueba">¿Dudas o sugerencias acerca del producto VxRuta?</h2>
        <p class="card-text-servicio">Si guarda alguna duda acerca de el funcionamiento de los módulos de VxRuta o busca alguna implementación personalizada para su negocio, rellene el formulario con sus datos y preguntas; un agente especializado de "Atti Solutions" se pondrá en contacto con usted para aclarar sus cuestionamientos.</p>
        
        <div class="row"> 
        <div class="col-md-6">
        <input type="text" name="nombreCompleto" id="nombreCompleto" placeholder="Nombre" class="gris form-control" required />
        </div>
        
        <div class="col-md-6">
        <input type="email" name="correoCompleto" id="correoCompleto" placeholder="Dirección de correo electrónico" class="gris form-control" />
        </div>
        </div>

         <br/>
        <div class="row"> 
        <div class="col-md-6">
        <input type="tel" name="telefonoCompleto" id="telefonoCompleto" placeholder="Número de teléfono"  class="gris form-control" title="Ingrese un numero telefonico valido" required />
        </div>
        
        <div class="col-md-6">
        <input type="text" name="empresaCompleto" id="empresaCompleto" placeholder="Empresa" class="gris form-control" />
        </div>
        </div>
        
        <br/>
        <div class="row"> 
        <div class="col-md-6">
        <select type="text" id="ciudadCompleto" name="ciudadCompleto" placeholder="Ciudad" class="gris form-control" required>
        <option value="">Seleccionar</option>
        </select>
        </div>
        
        <div class="col-md-6">
        <select type="text" id="giroComercial" name="giroComercial" placeholder="Giro Comercial" class="gris form-control" required>
        <option value="">Seleccionar</option>
        </select>
        </div>
        </div>

        <br/>
        <div class="row"> 
        <div class="col-md-12">
        <textarea type="text" name="comentario" class="gris form-control" rows="6" cols="82" placeholder="Comentario"></textarea>
        </div>
        </div>

      </div>

   </div>
   </div>

</div>
<div class="col-md-1"></div>
</div>

   <br/>
    </div>
    )
}

export default CardSugerencias