import React from "react";
import './Card.css'
import { Link } from "react-router-dom";

const CardModulos = () => {
return (
  <div>
  <br/>
  <div class="container-fluid">
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="cardSinborde card mb-3">
  <div class="row g-5">
  <div class="col-md-6">
    <img src='./img/funcion/modulos.png' height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-6">
      <div class="card-body">
        <h6 class="card-title">Función De Módulo</h6>
        <h2 class="card-title">Productividad En Sus Ventas</h2>
        <p class="parrafoFuncion">Obtenga el panorama completo del seguimiento de las ventas, observe y gestione todos los movimientos de sus representantes desde el panel web o la aplicación móvil.</p>
        <ul className=' list-unstyled'>
                      <li className='textplan text-left'> <img src='./img/funcion/lista.png' height='auto' width='8%' alt=""/>Programe la visita a sus clientes de acuerdo a la prioridad</li>
                      <li className='textplan text-left'> <img src='./img/funcion/lista.png' height='auto' width='8%' alt=""/>Vea a su personal y a todos sus clientes en el mapa</li>
                      <li className='textplan text-left'> <img src='./img/funcion/lista.png' height='auto' width='8%' alt=""/>Genere reportes de las actividades llevadas en el día o semana</li>
                      <li className='textplan text-left'> <img src='./img/funcion/lista.png' height='auto' width='8%' alt=""/>Fidelice clientes por medio de parámetros de descuento</li>
                      <li className='textplan text-left'> <img src='./img/funcion/lista.png' height='auto' width='8%' alt=""/>Imprima comprobantes de los movimientos realizados</li>
                      <li className='textplan text-left'> <img src='./img/funcion/lista.png' height='auto' width='8%' alt=""/>Venda rápidamente insumos de stock desde sus anventarios de producto</li>
                      <li className='textplan text-left'> <img src='./img/funcion/lista.png' height='auto' width='8%' alt=""/>Desarrolle un ambiente de trabajo colaborativo con todo su equipo de ventas</li>
                     </ul>
        <p class="nota"> <Link to='/planesprecios'><img src='./img/funcion/CONTRATAR.png' height='auto' width='50%' alt=""/></Link></p>
      </div>
    </div>
  </div>
</div>
</div>
<div class="col-md-1"></div>
</div>

</div>
</div>
    )
}

export default CardModulos