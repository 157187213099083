import React from "react";
import Backgroud from './CardContactar.png';

const CardTipoPlan = () => {

return (
<div>
<div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<div class="row"> 
 <div class="col-md-2">
 </div>
 <div class="col-md-8">
 <br/><br/><br/>
 <h3 class="subtituloTipoPlan">Para Contactar Con Nuestro Departamento De Desarrollo</h3>
 </div>
 <div class="col-md-2">
 </div>
 </div> 
 
<br/>
<br/>
<div class="row"> 
 <div class="col-md-1">
 </div>
 <div class="col-md-10" >
 <div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col">
    <div class="contactar card h-100" >
    <img src='./img/contactanos/telefono.png' height='auto' width='30%' alt=""/>
      <div class="carcard-body">
        <br/>
        <a href="tel:5564560704" className="titulo-contactar">55 6456 0704</a>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="contactar card h-100">
    
    <img src='./img/contactanos/mundo.png' height='auto' width='30%' alt=""/>
      <div class="card-body">
      <a href="https://atti.com.mx" className="titulo-contactar">www.atti.com.mx</a>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="contactar card h-100">
    <br/>
    <img src='./img/contactanos/correo.png' height='auto' width='30%' alt=""/>
      <div class="card-body">
      <a href="mailto:ventas@atti.com.mx" className="titulo-contactar">ventas@atti.com.mx</a>
      </div>
    </div>
  </div>
</div>
<br/><br/><br/>
</div>
<div class="col-md-1">
</div>
</div>
<br/>

</div>

    <br/><br/><br/>
    </div>
    )
}

export default CardTipoPlan