import React from "react";
import { Link } from "react-router-dom";

const Products = ( {productItems, handleAddProduct} ) => {

 return (

 <div >
  <div class="container-fluid" >
  <div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">

<h6 class="titulo-pantalla">PLANES Y PRECIOS</h6>
<h3 class="subtitulo-pantalla">Escoje el plan que más te convenga o cotiza por una implementación personalizada para tu negocio</h3>
<br/>

<div class="row row-cols-1 row-cols-md-3 g-4">
 {productItems.map((productItem) => (
   <div class="col">
     <div class="plan card h-100" >
     <div class="card-body">   
     <h5 className="tipoPlan"><strong>{productItem.name}</strong></h5>
     <p className="caracPlan">{productItem.subtitulo}</p>
     <p className="pagoPlan"><label className="peso">${productItem.price}</label> / Mes por usuario</p>
     <Link to='/cart'><button className="product-add-button col-md-8" onClick={() => handleAddProduct(productItem)}><img src='./img/home/ADQUIRIRPLAN.png' class="img-responsive-boton4" height='100%' width='100%' alt=""/></button></Link>
      <ul className='col-12 list-unstyled'>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Funcionamiento Online y Offline</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Genera cotizaciones y pedidos</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Registra cobros aplicados o por aplicar</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Gestión de Surtido</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Gestión de Entregas</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Registro de devoluciones</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Tickets por bluetooth, correo y whatsapp</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Soporte técnico</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Sin plazos forzosos</li>
        </ul>
     </div>
     </div>
   </div>
 ))}
</div>

<br/><br/>
<div class="row">
<div class="form-group col-md-3"></div>
<div class="form-group col-md-6">
<small class="buscas"><Link to='/planesprecios'><label class="buscas"><u>¿Buscas adecuar un plan especial para tu negocio?</u></label></Link></small>
</div> 
<div class="form-group col-md-3"></div>  
</div>
</div>
<div class="col-md-1"></div>
</div>

</div>
<br/><br/>
  </div>
    )
}

export default Products