import React from "react";
import { Link } from "react-router-dom";
import Backgroud from './software.png';

const CardSoftware = () => {

return (
<div>
<div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<br/>
<br/>
<br/>
<h1 class="tituloSoftware">Obtenga el software de VxRuta hoy mismo</h1>
<p class="sutituloSoftware" alingn="center">Registre la versión demostrativa y potencie el servicio de ventas en ruta de su negocio</p>
<br/>
<div class="row">
<div class="form-group col-md-5">
</div>
<div class="form-group col-md-3">
<p class="card-text"><Link to='/pruebagratis'><img src='./img/funcion/comenzar.png' height='auto' width='100%' alt=""/></Link></p>
</div> 
<div class="form-group col-md-4">
</div>  
</div>
<br/><br/><br/>
</div>
<br/><br/>
    </div>
    )
}

export default CardSoftware