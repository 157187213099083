import React from "react";
import './gridCards.css'
import { Link } from "react-router-dom";

const GridCards = () => {

    return (
<div>
<div class="container-fluid">
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="row"> 
<div class="col-md-5">
<div class="cardSinborde" >
  <div class="card-body">
    <h6 class="card-title">BENEFICIOS</h6>
    <h3 class="card-subtitle-beneficios">Optimize y Acelere Los Procesos De Venta Con VxRuta</h3>
    <p class="parrafo2">VxRuta ofrece la tecnología adecuada que le ayuda a administrar sus ventas y mejorar la gestión de ruta de sus colaboradores, en cualquier momento, cualquier lugar y cualquier dispositivo móvil.</p>
    <p class="parrafo2">Acelere sus ventas permitiendo a sus repartidores y vendedores acercarse a todos los datos relacionados del cliente que necesitan para realizar su operación de comercio. Lleve el control general de las transacciones de su negocio administrando todas las variables y generando reportes sobre los movimientos.</p>
   
    <Link to='/contactanos'><label class="buscas"><u>Contáctanos</u></label></Link>
  </div>
</div>
</div>

<div class="col-md-7">
<div class="row row-cols-2">
  <div class="col">
    <div class="cardSinborde">
    <Link to='/'><img src='./img/home/configuracion.png' height='auto' width='20%' alt=""/></Link>
      <div class="card-body">
        <h5 class="card-titulo-beneficios">Automatice los Procesos</h5>
        <p class="card-text-beneficios">Realice un monitoreo (web, app) de todas las actividades de tus colaboradores en ruta y genera reportes automáticos de los movimientos.</p>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="cardSinborde">
    <Link to='/'><img src='./img/home/ruta.png' height='auto' width='20%' alt=""/></Link>
      <div class="card-body">
        <h5 class="card-titulo-beneficios">Asignaciones de Ruta</h5>
        <p class="card-text-beneficios">Realiza una asignación fácil de personal para carga y cierre de rutas, mantente al tanto de los inventarios y registra los datos desde la app móvil.</p>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="cardSinborde">
    <Link to='/'><img src='./img/home/cotizaciones.png' height='auto' width='20%' alt=""/></Link>
      <div class="card-body">
        <h5 class="card-titulo-beneficios">Cotizaciones y Pedidos</h5>
        <p class="card-text-beneficios">Elabore cotizaciones o levantamiento de pedidos, añada insumos a través de una navegación rápida, con cantidades, precios y disponibilidad de stock.</p>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="cardSinborde">
    <Link to='/'><img src='./img/home/cartera.png' height='auto' width='20%' alt=""/></Link>
      <div class="card-body">
        <h5 class="card-titulo-beneficios">Administre su cartera de clientes</h5>
        <p class="card-text-beneficios">Obtenga acceso instantáneo a su cartera de clientes, información de contacto, historial de pedidos, adeudos, crédito disponible, etc.</p>
      </div>
    </div>
  </div>
</div>
    
</div>
</div>
</div>
<div class="col-md-1"></div>
</div>

</div>
<br/>
<br/>
</div>
    )
}

export default GridCards