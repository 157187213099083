import React from "react";
import { Link } from "react-router-dom";
import Backgroud from './fondoIntegracion.jpg';
const CardPedidos = () => {

    return (
        <div>
<div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="cardSinborde card-responsive mb-3" >
  <div class="row g-1">
    <div class="col-md-6">
    <br/><br/><br/><br/><br/><br/>
    <div class="card-body">
    <p class="card-title-pedido">CONEXIONES / INTEGRACIONES</p>
    <h3 class="card-subtitle-pedido">Integre Facilmente Sus Terminales De Cobro e impresión de Tickets con nuestro sistema.</h3>
    <p class="card-text-pedido">VxRuta te permite configurar tus periféricos de cobro e impresión de tickets para casi todas las marcas, tus vendedores en ruta podran expedir comprobantes de venta y cobranza desde la app mediante bluetooth y de igual manera compartirlas por correo electrónico.</p>
    <p class="card-text"><Link to='/planesprecios'><img src='./img/home/CONTRATAR.png' height='auto' width='50%' alt=""/></Link></p>
      </div>
    </div>
    <div class="col-md-6">
    <br/><br/><br/>
    <img src='./img/home/integracion.png' height='auto' width='100%'  alt=""/>
    </div>
  </div>
  </div>
</div>
<div class="col-md-1"></div>
</div>
    </div> 

    <br/>
    <br/>
    <br/>
        </div>
    )
}

export default CardPedidos