import React from "react";
import { Link } from "react-router-dom";
import Backgroud from './caracteristicas.png';

const CardCaracteristicas = () => {

return (
<div>
<div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<br/><br/>
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<h6 class="titulo-caracteristicas">Características</h6>
<h3 class="card-title-pregunta">Todas Las Posibilidades En Un Solo Sistema</h3>
<br/>
  <div class="row row-cols-3 ">
    <div class="col">
      <div class="p-1 "><Link to='#'><img src='./img/funcion/levantarPedido.png' height='auto' width='30%' alt=""/></Link></div>
      <p class="column-menu-caracteristicas">Levantar Pedido</p>
      <div class="col order-last">
      <p class="texto-caracteristica">Levanta desde la app una sección de productos desde su inventario de producto.</p>
      </div>
    </div>
    <div class="col">
      <div class="p-1"><Link to='#'><img src='./img/funcion/rutaventa.png' height='auto' width='30%' alt=""/></Link></div>
      <p class="column-menu-caracteristicas">Rutas De Venta</p>
      <div class="col order-last">
      <p class="texto-caracteristica">Planifique y asigne al personal de venta en ruta que se encargará de las ventas.</p>
      </div>
    </div>
    <div class="col">
      <div class="p-1"><Link to='#'><img src='./img/funcion/pagos.png' height='auto' width='30%' alt=""/></Link></div>
      <p class="column-menu-caracteristicas">Levantar Pagos</p>
      <div class="col order-last">
      <p class="texto-caracteristica">Registre pagos con tarjeta o efectivo desde la app móvil y genere comprobantes de movimiento.</p>
      </div>
    </div>
  </div>

  <div class="row row-cols-3">
    <div class="col">
      <div class="p-1 "><Link to='#'><img src='./img/funcion/mapa.png' height='auto' width='30%' alt=""/></Link></div>
      <p class="column-menu-caracteristicas">Mapa De Clientes</p>
      <div class="col order-last">
      <p class="texto-caracteristica">Ubique a sus clientes dentro de zonas y acelere los tiempos de llegada.</p>
      </div>
    </div>
    <div class="col">
      <div class="p-1"><Link to='#'><img src='./img/funcion/fidelizacion.png' height='auto' width='30%' alt=""/></Link></div>
      <p class="column-menu-caracteristicas">Fidelización</p>
      <div class="col order-last">
      <p class="texto-caracteristica">Cree un parámetro de descuento para sus productos de stock y seleccione clientes a beneficiar.</p>
      </div>
    </div>
    <div class="col">
      <div class="p-1"><Link to='#'><img src='./img/funcion/reportes.png' height='auto' width='30%' alt=""/></Link></div>
      <p class="column-menu-caracteristicas">Actividades y Reportes</p>
      <div class="col order-last">
      <p class="texto-caracteristica">Capture toda la información importante y vea las actividades de venta por cliente o pedido.</p>
      </div>
    </div>
  </div>
</div>
<div class="col-md-1"></div>
</div>
</div>

    </div>
 
    )
}

export default CardCaracteristicas