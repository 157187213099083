import React from "react";
import { Link } from "react-router-dom";

const CardPlanesPrueba = ({productItems, handleAddProduct}) => {

    return (
 <div>
<div class="row"> 
<div class="col-md-1"></div>
<div class="col-md-10" >
<h6 class="titulo-pantalla">PLANES Y PRECIOS</h6>
 <div class="row"> 
 <div class="col-md-2">
 </div>
 <div class="col-md-8">
 <h3 class="subtitulo-prueba">El Precio Y Plan Que Se Ajustan A las Necesidades de Su Marca</h3>
 </div>
 <div class="col-md-2">
 </div>
 </div>
 <br/>
 <br/>

 <div class="row row-cols-1 row-cols-md-3 g-4">
 {productItems.map((productItem) => (
   <div class="col">
     <div class="plan-prueba card h-100" >
     <div class="card-body">   
     <h5 className="tipoPlan"><strong>{productItem.name}</strong></h5>
     <p className="caracPlan">{productItem.subtitulo}</p>
     <p className="pagoPlan"><label className="peso">${productItem.price}</label> / Mes por usuario</p>
     <button className="product-add-button col-md-8" onClick={() => handleAddProduct(productItem)}><Link to='/cart'><img src='./img/prueba/ADQUIRIRPLAN.png' class="img-responsive-boton4" height='100%' width='100%' alt=""/></Link></button>
      <ul className='col-12 list-unstyled'>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Funcionamiento Online y Offline</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Genera cotizaciones y pedidos</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Registra cobros aplicados o por aplicar</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Gestión de Surtido</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Gestión de Entregas</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Registro de devoluciones</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Tickets por bluetooth, correo y whatsapp</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Soporte técnico</li>
          <li className='textplan text-left'> <img src='./img/planes/lista.png' height='auto' width='10%' alt=""/>Sin plazos forzosos</li>
        </ul>
     </div>
     </div>
   </div>
 ))}
</div>
<br></br> <br/>

<div class="row"> 
 <div class="col-md-3">
 </div>
 <div class="col-md-6">
 <Link to='/planesprecios'><label class="buscas"><u>¿Buscas adecuar un plan especial para tu negocio?</u></label></Link>
 </div>
 <div class="col-md-3">
 </div>
 </div>
</div>
<div class="col-md-1"></div>
</div>
 <br></br> <br></br><br></br><br></br>
  </div>
    )
}

export default CardPlanesPrueba