import React from "react";
import NavbarContactanos from '../Navbar/NavbarContactanos';
import CardSugerencias from "../card/CardSugerencias";
import CardContactar from "../card/CardContactar";
import FooterFuncion from "../footer/FooterFuncion";

const Contactanos = () => {

    return (
        <div>
        <NavbarContactanos/>
        <CardSugerencias/>
        <CardContactar/>
        <FooterFuncion/>
        </div>
    )
}

export default Contactanos