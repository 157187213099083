import React from "react";
import './Card.css'
import { Link } from "react-router-dom";
import Backgroud from './homeback.png';


const Card = () => {

    return (
  <div>
<div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-11">
<div class="cardSinborde card-responsive mb-3" >
  <div class="row g-1">
    <div class="col-md-5">
      <div class="card-body">
        <h6 class="card-title">Inicia nuestra versión demo por 15 días</h6><br/>
        <h1 class="card-title-inicio">Administre, planifique y acelere sus ventas con el sofware #1 de venta en Ruta</h1><br/><br/>
        <p class="parrafo" >Aumente la productividad en su campo de ventas con el software VxRuta, planifique, optimice rutas de venta, realice seguimientos de su personal, levante cotizaciones, pedidos, entre muchas más oportunidades para mejorar la rentabilidad en su negocio.</p><br/><br/>
        <p class="nota"> <Link to='/pruebagratis'><img src='./img/home/comenzar.png' height='auto' width='45%' alt=""/></Link>¡No se requiere tarjeta de crédito!</p>
      </div>
     
    </div>
    <div class="col-md-7">
    <img src='./img/home/carro.png' height='auto' width='100%' alt=""/>
    </div>

    <div class="container">
        <div class="row  ">
        <div class="col-md-5">
        <div ><Link to='/'><img src='./img/home/MICROSIP.png' height='auto' width='25%' alt=""/></Link><Link to='/'><img src='./img/home/SYCAR.png' height='auto' width='25%'alt=""/></Link></div>
        </div>
        </div>
    </div>
  </div>
  </div>

</div>
<div class="col-md-0"></div>
</div>

</div>
<br/>
    </div>
    )
}

export default Card