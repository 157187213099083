import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import './App.css';
import Comofunciona from './components/paginas/Comofunciona';
import Contactanos from './components/paginas/Contactanos';
import Home from './components/paginas/Home';
import Planesprecios from './components/paginas/Planesprecios';
import Pruebagratis from './components/paginas/Pruebagratis';
import Cart from './components/paginas/Cart';
import Data from "./components/data/Data";

function App() {
  const {productItems}=Data; 
  const [cartItems, setCartItems] = useState([]);
  const handleAddProduct =(product) =>{
    const ProductExist= cartItems.find((item) => item.id === product.id);
    if(ProductExist){
      setCartItems(cartItems.map((item) => item.id === product.id ?{...ProductExist, quantity: ProductExist.quantity +1}: item));

    } else{
     setCartItems([...cartItems,{...product, quantity: 1}]);
    }
  }

  const handleRemoveProduct = (product) => {
    const ProductExist= cartItems.find((item) => item.id === product.id);
    if(ProductExist.quantity === 1){
     setCartItems(cartItems.filter((item) => item.id !== product.id));
    } else{
      setCartItems(cartItems.map((item) => item.id === product.id ? {...ProductExist, quantity: ProductExist.quantity -1}: item));
    }
  }

  const handleCartClearance = () =>{
  setCartItems([]);
  }

  return (
    <div className="App">
     <Router>
     <Switch>
      <Route path='/' exact><Home cartItems={cartItems} productItems={productItems} handleAddProduct={handleAddProduct}/></Route> 
      <Route path='/comofunciona' exact component={Comofunciona}/> 
      <Route path='/beneficios' exact><Home cartItems={cartItems} productItems={productItems} handleAddProduct={handleAddProduct}/></Route>
      <Route path='/planesprecios' exact><Planesprecios cartItems={cartItems} productItems={productItems} handleAddProduct={handleAddProduct}/></Route>
      <Route path='/pruebagratis' exact><Pruebagratis cartItems={cartItems} productItems={productItems} handleAddProduct={handleAddProduct}/></Route> 
      <Route path='/contactanos' exact component={Contactanos}/> 
      <Route path='/cart' exact><Cart cartItems={cartItems} handleAddProduct={handleAddProduct} handleRemoveProduct={handleRemoveProduct} handleCartClearance={handleCartClearance}/></Route>
      <Route path="/atti" component={() => {global.window && (global.window.location.href = 'https://atti.com.mx/');return null;}}/>
     </Switch>
     </Router>
    </div>
  );
}

export default App;
