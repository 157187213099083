import React from "react";
import './Card.css'
import { Link } from "react-router-dom";

const CardPlanesPregunta = () => {
return (
  <div>
  <br/>
<div class="container-fluid" >
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="cardSinborde card mb-3">
  <div class="row g-5">
    <div class="col-md-6">
      <div class="card-body">
        <h2 class="planifique-prueba">¿Está invirtiendo Demasiado Tiempo Planificando Complejos Enrutamiento Para Sus Ventas?</h2>
        <p class="parrafoFuncion">Planifique todos sus procesos: entregas, levantamiento de pedidos, cobros, ventas y administración de colaboradores en ruta de una manera fácil, segura y eficiente. Genere rutas para sus colaboradores y lleve el control de su negocio en todo momento.</p>
        <div class="cardSinborde card mb-3">

  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/planes/optimiza.png' height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="textplanp"><strong>Optimice Su venta En Ruta</strong><br/>Asigne responsabilidad a sus colaboradores de ventas, diseñando y planificando sus actividades en ruta del día.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/planes/almacene.png' height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="textplanp"><strong>Almacene Todas Las Actividades Del Día</strong><br/>Guarde los historiales de todos los movimientos realizados por cliente, llamadas, visitas, peddios asociados, cobros, entre otros.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/planes/calidad.png' height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="textplanp"><strong>Mejore La Calidad En El Servicio De Ventas</strong><br/>Permita que sus representantes de venta accedan en tiempo real a formularios de pedidos, cotizaciones, ubicaciones, etc.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
  </div>
        <p class="nota"> <Link to='/pruebagratis'><img src='./img/planes/comenzar.png' height='auto' width='49%' alt=""/></Link>¡No se requiere tarjeta de crédito!</p>
      </div>
    </div>
    <div class="col-md-6">
    <img src='./img/planes/planesyprecios.png' height='auto' width='100%' alt="" />
    </div>
  </div>
</div>
</div>
<div class="col-md-1"></div>
</div>
</div>
</div>
    )
}

export default CardPlanesPregunta