import React from "react";

const Carousel = () => {

    return (
        <div>
  <div class="container-fluid" >    
  <div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<h6 class="titulo-pantalla">PANTALLAS DE SITIO & APP MÓVIL</h6>   
 <h2 class="subtitulo-pantalla">VxRuta está diseñada con una interfaz personalizable, modifique el panel con los colores y logotipo de su marca.</h2>          
<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
    <img src='./img/home/vista1.png' class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
    <img src='./img/home/vista2.png' class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
    <img src='./img/home/vista3.png' class="d-block w-100" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
<div class="col-md-1"></div>
</div>

</div>  
        </div>
    )
}

export default Carousel