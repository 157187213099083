import React from "react";
import Footer from "../footer/Footer";
import Card from "../card/Card";
import CardServicio from "../card/CardServicio";
import CardPedidos from "../card/CardPedidos";
import CardEvaluacion from "../card/CardEvaluacion";
import Carousel from "../carousel/Carousel";
import Columns from "../columns/Columns";
import GridCards from "../gridCards/GridCards";
import CardDudas from "../card/CardDudas";
import CardEnrutamiento from "../card/CardEnrutamiento";
import Navbar from '../Navbar/Navbar';
import Products from "../productos/Products"

const Home = ({cartItems, productItems , handleAddProduct}) => {

    return (
        <div>
        <Navbar cartItems={cartItems}/>    
        <Card/>
        <Columns/>
        <GridCards/>
        <CardEnrutamiento/>
        <CardServicio/>
        <Carousel/>
        <CardPedidos/>
        <CardEvaluacion/>
        <Products productItems={productItems} handleAddProduct={handleAddProduct}/>
        <CardDudas/>
        <Footer/>
        </div>
    )
}

export default Home