import React from "react";
import { Link } from "react-router-dom";
import './Navbar.css';


const NavbarCart = ({ cartItems }) => {

return (
<div>
<nav className="navbar navbar-expand-lg navbar-light sticky-top" >
  <div className="container-fluid">
      <Link to='/'>
      <img src='./img/home/logotipo.png'  class="logo-emonedero" alt=""/>
       </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav mx-auto">
        <li className="nav-item">
          <Link className="nav-link l-home" aria-current="page" to='/'>Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link l-home" to='/comofunciona'>¿como funciona?</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link l-home" to='/planesprecios'>Planes y precios</Link>
        </li>
        <li className="h-nav-item">
          <Link className="nav-link l-home" to='/pruebagratis'>¡Prueba gratis!</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link l-home" to='/contactanos'>Contactanos!</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link  home-active" to='/cart'><i class="cart bi bi-cart3" /><span className="cart-length"> {cartItems.length === 0 ? "": cartItems.length}</span></Link>
        </li>
        <li className="nav-item">
        <Link className="cart" to='/pruebagratis'><img src='./img/home/pruebagratis.png' class="boton-probar" height='auto' width='100%' alt=""/></Link>
        </li>
      </ul>
    </div>
  </div>
  <br/>
</nav>
</div>
    )
}

export default NavbarCart