
import React from "react";
import Backgroud from './CardPlanPrueba.png';

const CardSuscripcionPrueba = () => {

    return (
    <div>
 <h6 class="titulo-pantalla">NUESTRO SERVICIO</h6>
 <br/>
 <div class="row"> 
 <div class="col-md-2"></div>
 <div class="col-md-8">
 <h3 class="subtitulo-prueba">Descubra Como VxRuta le Otorga el Potencial de Optimización y crecimiento a Sus Procesos De Venta</h3>
 </div>
 <div class="col-md-2"></div>
 </div> 
 <br/>
 <br/>

<div class="row"> 
 <div class="col-md-1"></div>
 <div class="col-md-10" >
 <div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>

 <div class="container-fluid" >     
       <br/>
       <br/>
   <div class="row"> 
   <div class="col-md-6">
       <br/><br/>
   <p class="d-titulo">Descargue VxRuta</p>
   <h2 class="d-subtitulo">Suscríbase a Nuestro Sistema y Reciba Una Versión Demostrativa</h2><br/>
   <p class="d-desgarga" >El equipo de desarrollo pone a su disposición la versión demostrativa del ecosistema de ventas en ruta, con esta demo usted tendrá acceso a todos los módulos y opciones disponibles dentro del sistema administrativo. Desgargue hoy mismo la versión evaluativa y descubra todas las posibilidades que este software le ofrece para administrar y mejorar sus servicios de enrutamiento.</p>
   </div>
   <div class="col-md-1"></div>
    <div class="col-md-5">
    <form method="post" id="sample_form" class="form-horizontal" enctype="multipart/form-data" autocomplete="off">
                            
                                <div class="form-group">
                                    <label  class="control-label col-md-4" >Nombre </label>
                                    <div class="col-md-12">
                                    <input type="text" name="nombreClientep" id="nombreClientep" placeholder="Nombre" class="form-control" required />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-2" >Apellido </label>
                                    <div class="col-md-12">
                                    <input type="text" name="apellidoClientep" id="apellidoClientep" placeholder="Apellido" class="form-control" required />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-12" >Número de teléfono</label>
                                    <div class="col-md-12">
                                        <input type="tel" name="telefonoClientep" id="telefonoClientep" placeholder="Número de teléfono"  class="form-control" title="Ingrese un numero telefonico valido" required />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-12" >Dirección de correo electrónico </label>
                                    <div class="col-md-12">
                                        <input type="email" name="correoClientep" id="correoClientep" placeholder="Dirección de correo electrónico" class="form-control" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-3" >Empresa </label>
                                    <div class="col-md-12">
                                        <input type="text" name="empresaClientep" id="empresaClientep" placeholder="Empresa" class="form-control" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-2" >Estado </label>
                                    <div class="col-md-12">
                                    <select type="text" id="estadoClientep" name="estadoClientep" placeholder="Estado" class="form-control" required>
                                            <option value="">Seleccionar</option>
                                                <option value=""></option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-6" >Cantidad de rutas</label>
                                    <div class="col-md-12">
                                        <input type="text" name="rutasClientep" id="rutasClientep" placeholder="Cantidad de rutas" class="form-control" required />
                                    </div>
                                </div>
                                <div class="form-group" align="left">
                                    <input type="hidden" name="action" id="action" />
                                    <input type="hidden" name="hidden_id" id="hidden_id" />
                                    <button type="submit" class ="registrar col-md-12" name="action_button" id="action_button" value="Add"><img src='./img/prueba/REGISTRARSE.png' height='auto' width='100%' alt=""/></button>
                                </div>
                            </form>  
    </div>
    </div>
    </div>
    </div>
 </div>
 <div class="col-md-1"></div>
 </div>
 <br/><br/>
    </div>
        )
    }
    
export default CardSuscripcionPrueba